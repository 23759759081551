<div class="root">
    <div class="error">404</div>
    
    <div class="console">
      <pre>
  | |/ /         (_) |                
  | ' / __ _ _ __ _| |__   ___  _   _ 
  |  < / _` | '__| | '_ \ / _ \| | | |
  | . \ (_| | |  | | |_) | (_) | |_| |
  |_|\_\__,_|_|  |_|_.__/ \___/ \__,_|.ch  
      </pre>
      <p>$>{{$i18n.label().e404}}</p>
      <p>$><a href="/">reboot [Yes]</a>|[No]</p>
      
    </div>
  
  </div>
  