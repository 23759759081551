<div class="footer" [class.display]="isReady">
  <div class="main">
    <div class="line ">
      <div class="elem" [hidden]="!getMenuItems('about').length">
        <ul>
          <li *ngFor="let elem of getMenuItems('about')"><a [href]="'/store/'+store+elem.url" [innerHtml]="elem.name[locale]"></a></li>
        </ul>
      </div>
      <div class="elem" [hidden]="!getMenuItems('links').length">
        <ul>
          <li *ngFor="let elem of getMenuItems('links')"><a target="_new" [href]="elem.url" [innerHtml]="elem.name[locale]"></a></li>
          <li>&nbsp;<b>v{{VERSION}}</b></li>
        </ul>
      </div>
    </div>
  </div>      
</div>