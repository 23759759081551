<div class="bottom-actions " >  
  <kng-search-bar class="force-display" [hidden]="!show"></kng-search-bar>

  <div class="bottom-bar hide-lg" [hidden]="show">
    <button class="action" routerLinkActive="active" (click)="doClearScroll()" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/store',store]"><span class="material-symbols-outlined icon">home</span><div>{{label.nav_bottom_home}}</div></button>  
    <button class="action" routerLinkActive="active" (click)="doClearScroll()" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/store',store,'home']"><span class="material-symbols-outlined icon">manage_search</span><div>{{label.nav_bottom_browse}}</div></button>  
    <button class="action" routerLinkActive="active" (click)="doClearScroll()" [routerLink]="['/store',store,'home','shops']"><span class="material-symbols-outlined icon">storefront</span><div>{{label.nav_bottom_shops}}</div></button>  
    <button class="action hide" routerLinkActive="active" (click)="doClearScroll()" [routerLink]="['/store',store,'home','subscription']"><span class="material-symbols-outlined icon pink bold">magic_button</span><div>{{label.nav_subscription}}</div></button>  
    <button class="action" routerLinkActive="active" (click)="doClearScroll()" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/store',store,'home','cart','default']">
      <div class="cart-items-count-mobile"></div>
      <span class="material-symbols-outlined icon">shopping_cart</span>
      <div>{{label.nav_bottom_cart}}</div>
    </button>    
  </div>
</div>  

<div class="results" [hidden]="!show">
  <button  (click)="doToggle()" class="close">
    <span class="material-symbols-outlined large" >{{show?'close':'menu'}}</span>
  </button>  

  <!-- CATS -->
  <!-- <ul class="category" [hidden]="products.length">
    <li *ngFor="let category of categories" 
        [hidden]="!category.active||products.length" 
        (click)="doGoCategory(category.slug)">
      <i [class]="category.image" class="icon"></i>
      <span>&nbsp;{{category.name}}</span>
    </li>                
  </ul> -->

  <!-- PRODUCTS -->
  <ul class="mdc-list  " [hidden]="!products.length">
    <li class="twoline" *ngFor="let product of products">
      <a class="avatar" [routerLink]="['/store',store,'home','products',product.sku]">
        <img [src]="product.photo.url + '/-/resize/128x/)'" />
      </a>
      <div class="list-text">
        <div class="title" [routerLink]="['/store',store,'home','products',product.sku]">{{product.title}}</div>
        <div class="list-text-secondary">
          {{product.pricing.part}} -
          <span [hidden]="product.attributes.discount">
            Fr {{product.pricing.price| number:'1.2-2'}}
          </span>
          <span [hidden]="!product.attributes.discount">
            <span class="red">Fr {{product.pricing.discount| number:'1.2-2'}}</span>
          </span>
    
          <a href="javascript:;" class="gray" i18n (click)="addToCard(product)" [hidden]="product.variants.length">
            <i class="material-symbols-outlined" style="transform: translateY(5px);font-size:20px">add</i>ajouter</a>
        </div>
  
      </div>
    </li>
  </ul>
  <!-- SHOPS -->
  <!-- <kng-shops></kng-shops>   -->


  <!-- <div [hidden]="!findGetNull" class="info">
    <h4>Pas de résultat avec votre recherche</h4>
  </div> -->

</div>

