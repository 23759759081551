<div class="container">
  <div class="alert alert-success" role="alert" *ngIf="validate" >
      <strong >{{i18n[locale].title1}}</strong> <br/>
      <span >{{i18n[locale].title2}}</span>
      <div>
          <i class="material-symbols-outlined primary">check_circle_outline</i>        
      </div>
  </div>


  <div class="alert alert-error" role="alert" *ngIf="error">
      <strong >{{i18n[locale].warning}}</strong> <br/>
      <div class="primary">{{error}}</div>
      <div ><span [innerHtml]="i18n[locale].warning"></span></div>
      <div>
        <i class="material-symbols-outlined">error_outline</i>
      </div>
  </div>

</div>
