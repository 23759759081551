<!-- TOP -->
<div class="container welcome-top">
  <div class="message" [hidden]="!isMessageActive">
    <span [innerHtml]="welcomeMessage"></span>
  </div>
  <div class="menu">
    <div class="logo" >
      <img [src]="K_BRAND" alt=""> Karibou.ch
    </div>
    <div class="menu-item" (click)="doLangSwitch()">
      <span class="button-primary">{{_('action_lang_switch')}}</span>
    </div>


  </div>
  <div class="welcome" [class.exited]="exited">
    <img [src]="tagline.image" [hidden]="!tagline.image"/>
    <canvas  #background ></canvas>
    <h1 [innerHtml]="tagline.h[locale]"></h1>
    <div class="content" [innerHtml]="tagline.p[locale]">
    </div>
    <div class="b2b-query" hidden><span class="new">NEW!</span> <a class="link" (click)="doB2B()"> {{_('title_b2b')}}</a> </div>
    <div class="actions">
      <button class="CTA"  (click)="waiting=true" [routerLink]="homeDestination">
        <span class="material-symbols-outlined icon" [class.waiting]="waiting"> clock_loader_10</span>
        {{c2a}}
      </button>
    </div>
  </div>

  <!-- BOXES -->
  <div #boxes class="how-it-works" [innerHtml]="about.p[locale]"></div>

  <div class="brands">
    <img [src]="about.image" alt="">

    <h3 [innerHtml]="about.h[locale]"></h3>

    <!-- CTA -->
    <div class="actions">
      <button  class="CTA" (click)="waiting=true" [routerLink]="homeDestination">
        <span class="material-symbols-outlined icon" [class.waiting]="waiting"> clock_loader_10</span>
        {{c2a}}
      </button>
    </div>

  </div>


</div>
<div class="container separator"></div>

<!-- FAQ -->
<div class="container faq">
  <div class="content-main">

    <h1>Questions</h1>
    <div class="faq-box" *ngFor="let faq of faqs;let idx=index">
      <div class="title">
        {{faq.q[locale]}}
      </div>
      <div class="content" [innerHtml]="faq.a[locale]">
      </div>
    </div>

  </div>
</div>
<div class="container fees">
  <div class="content-main">
    <div class="postalcode">
      <p>{{i18n[locale].title_postal}}</p>
      <input type="text" placeholder="Code Postal" pattern="^[0-9]{4}$" type="number"  [(ngModel)]="postalCode">
      <div class="price">
        {{shippingLabel}} <b [hidden]="!shippingPrice">{{shippingPrice}} fr</b><br/>
        <div [innerHtml]="getShippingDiscount('A')"></div>
        <div [innerHtml]="getShippingDiscount('B')"></div>
        <div [innerHtml]="getShippingCustomHours()"></div>
      </div>
    </div>

    <div class="actions">
      <button  class="CTA"  (click)="waiting=true" [routerLink]="homeDestination">
        <span class="material-symbols-outlined icon" [class.waiting]="waiting"> clock_loader_10</span>
        {{c2a}}
      </button>
    </div>

  </div>
</div>

<kng-footer [config]="config" ></kng-footer>
<!-- 
<div class="background-grid" [class.large]="photos.length < 20">
  <div class="items-list-wrap">
    <ul class="items-list" >
      <li class="has-details item" *ngFor="let photo of photos"  
        [ngStyle]="{'background-image': 'url(' + photo + '-/preview/350x350/)'}"></li>
    </ul>        
  </div>
-->