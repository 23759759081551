<h2>{{i18n[locale].title_subscription}}</h2>
<div class="customer" >
  <div class="kng-boxed subscription" [hidden]="contracts.length">
    <div class="label">{{i18n[locale].title_subscription}}</div>
    <div class="">
      <p>Aucun abonnement actif</p>
    </div>
    <button [routerLink]="'/store/'+store+'/home/subscription'">Découvrir</button>
  </div>

  <div class="kng-boxed  subscription" [class.premium]="contract.plan=='patreon'" *ngFor="let contract of contracts">
    <div class="label">
      {{getContractDescription(contract)}}
    </div>
    <div class="content">
      <div class="items">
        <ul>
          <li *ngFor="let item of contract.items"><b>{{item.quantity}}x</b> {{item.title}} ({{item.part}})</li>
        </ul>
      </div>
  
      <div class="bottom"> <button (click)="onOpen(contract)">{{getContractAction(contract)}}</button></div>
    </div>
  </div>
</div>

<!-- ITEMS POPUP -->
<ng-container *ngIf="currentContract">

  <div class="blured" ></div>
  <div class="container top">
    <button (click)="onClose()" class="close">
        <span class=" material-symbols-outlined">close</span>
    </button>
    <div class="subscription-container" >
      <h2>{{getContractDescription(currentContract)}} </h2>

      <h3>{{i18n[locale].subtitle_subscription_items}} {{getFrequency(currentContract)}}</h3>
      <ul *ngIf="currentContract.patreon.length; else shippingTemplate">
        <li class="contrasted" *ngFor="let item of currentContract.patreon"><b>{{item.quantity}}x</b> <span class="left">{{item.title}}</span> <b>{{(item.unit_amount/100)|number:'1.2-2'}} fr</b></li>
      </ul>
      <ng-template #shippingTemplate>
        <ul>
          <li class="contrasted" *ngFor="let item of currentContract.items"><b>{{item.quantity}}x</b> <span class="left">{{item.title}}</span> <b>{{item.fees|number:'1.2-2'}} fr</b></li>
        </ul>
        <h3>{{i18n[locale].subtitle_subscription_service}} </h3>
        <ul>
          <li class="contrasted" *ngFor="let item of currentContract.services"><b>{{item.quantity}}x</b> <span class="left">{{item.title}}</span> <b>{{item.fees|number:'1.2-2'}} fr</b></li>
        </ul>  
      </ng-template>

      <div class="block-info red" *ngIf="currentContract.status=='canceled'">
        {{i18n[locale].subtitle_subscription_end}}
      </div>

      <h3 [hidden]="contract_requires_method">{{i18n[locale].subtitle_subscription_options}} </h3>


      <ul>
        <!---Update content -->
        <li [hidden]="currentContract.status=='canceled' || currentContract.patreon.length || contract_requires_action || contract_requires_method" class="contrasted-sm">
          <div class="title">{{i18n[locale].subtitle_subscription_action}}</div>
          <button class=""  (click)="onUpdateCart()">{{label.nav_bottom_browse}}</button>
        </li>

        <!---3D secure -->
        <li [hidden]="!contract_requires_action" >
          <div class="title_payment red">Votre abonnement n'est pas encore actif </div>          
          <button class=""  (click)="onConfirmPaymentIntent()">{{i18n[locale].subtitle_subscription_confirm_method}}</button>
        </li>

        <!---new payment method -->
        <li [hidden]="!contract_requires_method">
          <div class="title_payment red">Activer votre abonnement avec une nouvelle méthode de paiement</div>          
          <div class="method" *ngIf="userPayment">
            Celle-ci ne fonctione plus →
            <span class="method name">{{userPayment.issuer}}  **{{userPayment.last4}}</span>&nbsp;
            <span class="note">{{userPayment.expiryToDate()|date:'MM/y'}}</span>  
          </div>
          <kng-user-payment [config]="config" [user]="user" (updated)="onUpdatePaymenMethod($event)"></kng-user-payment>

        </li>


        <li class="contrasted-sm" [hidden]="contract_requires_method">
          <div class="title">{{i18n[locale].subtitle_subscription_start}}   </div>
          <label class="date bold kng-chip">{{currentContract.start|date:'EEEE d MMM YYYY'}}</label>
        </li>

        <li class="contrasted-sm" *ngIf="!currentContract.patreon?.length">
          <div class="title">{{llabel.subtitle_subscription_status}}   </div>
          <label for="subdate" class="date kng-chip">
            <b>{{getFrequency(currentContract)}}</b> / 
            <b>{{getDayOfWeek(currentContract.dayOfWeek)}} ({{getShippingTime(currentContract)}})</b>  <b class="green">✓</b> 
          </label>      
        </li>
        <li class="contrasted-sm" [hidden]="currentContract.status=='paused' || currentContract.status=='canceled' || contract_requires_method">
          <div class="title" [hidden]="currentContract.status=='paused'">
            <span >{{llabel.subtitle_subscription_pause}}&nbsp;</span>            
            <span class="bold">{{pauseUntil|date:'EEE d MMM'}} ?</span>
          </div>      

          <button class="date"  (click)="onPause(pauseUntil)"   [disabled]="pauseInDays<7">{{i18n[locale].subtitle_subscription_pause_action}} &nbsp;<b class="align-right">↦</b></button>
          <input type="date" id="subdate" (change)="pauseUntil = $event.target.valueAsDate"  [valueAsDate]="until" [min]="until| date:'yyyy-MM-dd'"  />
        </li>
        <li class="contrasted-sm" [hidden]="currentContract.status!='paused'">
          <div class="bold title">{{llabel.subtitle_subscription_paused}}  </div>
          <label for="subdate" class="date kng-chip">
            <b>{{currentContract.pauseUntil| date:'EEE d MMM'}} ✓</b> 
          </label>      
        </li>
        <li class="separator"><hr class="dashed"></li>
        <li [hidden]="currentContract.status=='canceled'">
          <div class="red" >{{llabel.subtitle_subscription_cancel}}</div>
          <button class="danger"  (click)="onDelete()">{{label.action_del}}</button>
        </li>
      </ul>
      <p class="block-info red" [hidden]="!error"><b>Opps:</b> {{error}}</p>

      <!-- ORDER FEEDBACK -->

      <!-- FEEDBACK ACTIONS -->

    </div>
  </div>    

</ng-container>
